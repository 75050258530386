/* eslint-disable import/order */
import {
  Layout as AntdLayout,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Row,
  Spin,
  Typography,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { getParamLabelAndUnit, getShortForm } from '../../../../../utils/utils';

import EditableFormNegotiate from '../../../../../components/EditableFormNegotiate';
import ErrorResult from '../../../../../components/ErrorResult';
import NegotiationMessage from '../../../../../components/NegotiationMessage';
import ProcurementParameters from '../../../../../components/ProcurementParameters';
import StandardNegotiateTable from '../../../../../components/StandardNegotiateTable';
// import ValueParameters from '../../../../../components/ValueParameters';
// import { LoadingOutlined } from '@ant-design/icons';
// import isInProgressChannel from '../../../../../utils';
import moment from 'moment';
import qs from 'qs';
import { useLocation } from 'dva';
// import CommentDrawer from '../../../../../components/CommentDrawer';
// import PageHeaderWrapper from '@ant-design/pro-layout';
import useStyles from './styles';

const ChannelInfoWrapper = ({ friendlyName, role, orderNo, products, customer }) => {
  const classes = useStyles();
  const headerName =
    // eslint-disable-next-line no-nested-ternary
    friendlyName === undefined
      ? role === 'ROLE_BUYER'
        ? [...new Set((products || []).map((p) => p.tenant.name))]
        : customer.name
      : friendlyName;

  return (
    <div className={classes.channelInfoContainer}>
      <Row className="channelInfo" justify="space-between">
        <Col span={role.length >= 0 && role === 'ROLE_BUYER' ? 12 : 24}>
          <div>
            <Avatar>
              {Array.isArray(headerName) ? getShortForm(headerName[0]) : getShortForm(headerName)}
            </Avatar>
            <strong style={{ marginLeft: '10px' }}>{headerName}</strong>
            <strong style={{ marginLeft: '10px' }}> || </strong>
            <strong style={{ marginLeft: '10px' }}> Order ID: </strong>
            <strong style={{ marginLeft: '2px' }}>{orderNo}</strong>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const getHeaders = () => [
  {
    title: 'Item Name',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    key: 'uom',
    render(text) {
      return {
        props: {
          style: { textTransform: 'capitalize' },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: 'Proposed Quantity',
    dataIndex: 'baseline_quantity',
    key: 'baseline_quantity',
    editable: true,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: '12%',
  },
  {
    title: 'Proposed price',
    dataIndex: 'baseline_price',
    key: 'baseline_price',
    editable: true,
    // render: (text, row) => {
    //   console.log(text, 'text************8');
    //   return !Number.isNaN(Number(text))
    //     ? new Intl.NumberFormat('en-US', {
    //         style: 'currency',
    //         currency: row.currency || 'USD',
    //       }).format(text)
    //     : text;
    // },
  },
  {
    title: 'Last Quoted Quantity',
    dataIndex: 'previous_baseline_quantity',
    key: 'previous_baseline_quantity',
    editable: false,
    className: '#e0e0e063',
    render(text) {
      console.log('text: ', text);
      return {
        props: {
          style: { backgroundColor: '#e0e0e063' },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: 'Last Price Quoted',
    dataIndex: 'previous_baseline_price',
    key: 'previous_baseline_price',
    editable: false,
    render(text, row) {
      console.log('row: ', row);
      return {
        props: {
          style: { backgroundColor: '#e0e0e063' },
        },
        children: (
          <div>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: row.currency || 'USD',
            }).format(text)}
          </div>
        ),
      };
    },
    // render: (text, row) => readOnly ? new Intl.NumberFormat('en-US', { style: 'currency', currency: row.currency || 'USD' }).format(text) : text
  },
];

const headersEdit = (isEditable) => [
  {
    title: 'Item Name',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    key: 'uom',
    render(text) {
      return {
        props: {
          style: { textTransform: 'capitalize' },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: !isEditable ? 'Quantity' : 'Proposed Quantity',
    dataIndex: 'baseline_quantity',
    key: 'baseline_quantity',
    editable: true,
    render: (text) => parseFloat(text).toFixed(2),
    // render: (text, row) => `${text} ${row.uom}`
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: !isEditable ? 'Price' : 'Proposed price',
    dataIndex: 'baseline_price',
    key: 'baseline_price',
    editable: true,
    render: (text, row) =>
      !Number.isNaN(Number(text))
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: row.currency || 'USD',
          }).format(text)
        : text,
  },
];

const schema = [
  {
    name: 'payment_terms',
    label: 'Payment Terms',
    is_inverse: false, // lower is better
    type: 'number',
    parameters: {
      weight: {
        label: 'Weight',
        min: 0,
        max: 1,
        type: 'number',
        disabled: false,
      },
      value: {
        label: 'Range',
        min: 0,
        max: 90,
        unit: 'Days',
        type: 'slider',
        step: 15,
        disabled: false,
      },
    },
  },
  {
    name: 'contract_terms',
    label: 'Term of contract',
    is_inverse: true, // higher is better
    type: 'number',
    parameters: {
      weight: {
        label: 'Weight',
        min: 0,
        max: 1,
        type: 'number',
        disabled: false,
      },
      value: {
        label: 'Range',
        min: 3,
        max: 24,
        unit: 'Months',
        type: 'slider',
        step: 3,
        disabled: false,
      },
    },
  },
  {
    name: 'on_demand_delivery',
    label: 'On demand delivery',
    is_inverse: false,
    parameters: {
      weight: {
        label: 'Weight',
        min: 0,
        max: 1,
        type: 'number',
        disabled: false,
      },
    },
    type: 'checkbox',
  },
];

const { Header } = AntdLayout;
const Layout = ({
  rfqId,
  // client,
  sid,
  messages,
  sendMessage,
  form,
  friendlyName,
  channelData,
  setValidChannels,
  validChannels,
  setFormEditable,
  formEditable,
  role,
  negobot,
}) => {
  const [channel, setChannel] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(true);
  const { bot_parameter: parameters, product_parameters: productParameters } =
    channel?.session || {};
  const currency =
    channel && ((channel?.products || []).find(() => true) || { currency: 'USD' }).currency;
  const lastOffer = channel?.session && channel?.session.offers[channel?.session.offers.length - 1];
  const lastValue = channel?.session && channel?.session.offers.filter((a) => a.by === 'buyer');
  const lastItem = lastValue && lastValue[lastValue.length - 1];
  const maxConcessionRound = channel?.session.bot_parameter.max_concession_round;
  const isAllRoundsCompleted =
    lastOffer && lastOffer.by === 'bot' && maxConcessionRound === lastOffer.round;
  const isCustomerCancelled = channel?.status === 'customer_rejected';
  const isCustomerAccepted = channel?.status === 'customer_accepted';
  const isSupplierCancelled = channel?.status === 'rejected';
  const isNegotiationCompleted = channel?.status === 'completed';
  const isCompleted = channel?.rfq?.status === 'completed';
  const isButtonDisabled = channel?.status === 'init' || channel?.status === 'in_progress';
  const isLastOfferByBot =
    // channel?.rfq?.status === 'active' &&
    channel?.session?.offers[channel?.session.offers.length - 1]?.by === 'bot' ||
    channel?.session?.offers[channel?.session.offers.length - 1]?.by === 'supplier';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
  });
  const getStepCount = (productId) => {
    const productFound = channel.products.find((p) => p.id === productId);
    return productFound.parameter.step_count;
  };
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const userType = localStorage.getItem('role');

  const rfqItems = lastItem
    ? lastItem.rfq_offer.rfq_items.map((item) => ({
        ...item,
        previous_baseline_price: item.baseline_price,
        baseline_price: '',
        previous_baseline_quantity: item.baseline_quantity,
        baseline_quantity: '',
        step_count: getStepCount(item.product_id),
        // productFound.parameter.step_count,
      }))
    : channel?.session?.rfq_items.map((item) => ({
        ...item,
        baseline_price: '',
        baseline_quantity: '',
        step_count: getStepCount(item.product_id),
      }));

  // const rfqItems = lastItem
  // ? lastItem.rfq_offer.rfq_items.map((item) => {
  //     const productFound = channel.products.find((p) => p.id === item.product_id);

  //   return {
  //     ...item,
  //     previous_baseline_price: item.baseline_price,
  //     baseline_price: '',
  //     previous_baseline_quantity: item.baseline_quantity,
  //     baseline_quantity: '',
  //     step_count : productFound.parameter.step_count,
  //     test: 'YYYY',
  //   }
  // },null

  // // : channel?.session?.rfq_items.map((item) => ({
  // //     ...item,
  // //     baseline_price: '',
  // //     baseline_quantity: '',
  // //   }));
  const FormItem = Form.Item;
  const [total, setTotal] = useState(0);
  const [newrfqItems, setRfqItems] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (channelData) setChannel(channelData);
  }, [messages, channelData]);

  const onItemsSave = (fields) => {
    const offerTotal = fields.reduce(
      (aggregator, current) =>
        aggregator +
        (!current.baseline_price ? 0 : current.baseline_price * current.baseline_quantity),
      0,
    );
    setTotal(offerTotal);
    setRfqItems(fields);
  };
  const makeOffer = () => {
    form.validateFields(async (err, fieldsValue) => {
      console.log('fieldsValue: ', fieldsValue);
      if (err) return;
      const rfqParameters = Object.keys(fieldsValue).map((key) => ({
        name: key,
        value: +(fieldsValue[key] || 0),
        label: schema.find((s) => s.name === key).label,
      }));
      if (newrfqItems.length > 0) {
        const isPriceEmpty = newrfqItems.some((item) => item.baseline_price === '');
        const isQuantityEmpty = newrfqItems.some((item) => item.baseline_quantity === '');
        console.log('newrfqItems: ', newrfqItems);
        console.log('parameters:  ', rfqParameters);
        if (isPriceEmpty) {
          message.error('Proposed Price is a required field');
          return;
        }
        if (isQuantityEmpty) {
          message.error('Proposed Quantity is a required field');
          return;
        }
        const botFirstOffer =
          channel?.session && channel?.session.offers.find((o) => o.by === 'bot' && o.round === 0);
        const allParams = botFirstOffer.rfq_offer.rfq_parameters.map((p) => p.name);
        const botPriceQuantity = botFirstOffer.rfq_offer.rfq_items[0];
        const quantityParam = productParameters.find((p) => p.name === 'quantity');
        const isQuantityValid = newrfqItems.some(
          (item) =>
            Number(item.baseline_quantity) < quantityParam.min ||
            Number(item.baseline_quantity) > botPriceQuantity.baseline_quantity,
        );
        if (isQuantityValid) {
          message.error(
            `Proposed Quantity should be in range of ${quantityParam.min} to ${botPriceQuantity.baseline_quantity}`,
          );
          return;
        }
        if (!rfqParameters) {
          message.error(`${allParams.toString()} - required fields`);
          return;
        }
      } else {
        message.error('Proposed Price & Quantity are required fields');
        return;
      }

      // eslint-disable-next-line no-param-reassign
      channel.session.rfq_items = newrfqItems;
      channel.session.rfq_parameters = rfqParameters;

      const responsedata = await negobot.postMessage({
        rfqId,
        payload: {
          rfq_items: channel.session.rfq_items,
          rfq_parameters: channel.session.rfq_parameters,
        },
        token: params.token,
      });
      const index = validChannels.findIndex((v) => v.channel_id === channel.channel_id);
      // eslint-disable-next-line no-param-reassign
      console.log(responsedata, 'responsedata');
      console.log(validChannels, 'validChannels');
      // eslint-disable-next-line no-param-reassign
      validChannels[index] = responsedata.data.data.session.data;
      setValidChannels(validChannels);
      setChannel(responsedata.data.data.session.data);
      setTotal(0);
      setFormEditable(false);
      setButtonDisable(true);
    });
  };
  const confirmNegotiation = async () => {
    const responsedata = await negobot.postMessage({
      rfqId,
      accept: true,
      payload: {
        rfq_items: channel.session.rfq_items,
        rfq_parameters: channel.session.rfq_parameters,
      },
      token: params.token,
    });
    const index = validChannels.findIndex((v) => v.channel_id === channel.channel_id);
    // eslint-disable-next-line no-param-reassign
    validChannels[index] = responsedata.data.data.session.data;
    setValidChannels(validChannels);
    setChannel({ ...responsedata.data.data.session.data });
  };
  const exitNegotiation = async () => {
    // eslint-disable-next-line no-param-reassign

    const responsedata = await negobot.postMessage({
      rfqId,
      exitNegotiation: true,
      payload: {
        rfq_items: channel.session.rfq_items,
        rfq_parameters: channel.session.rfq_parameters,
      },
      token: params.token,
    });
    const index = validChannels.findIndex((v) => v.channel_id === channel.channel_id);
    // eslint-disable-next-line no-param-reassign
    validChannels[index] = responsedata.data.data.session.data;
    setValidChannels(validChannels);
    setChannel({ ...responsedata.data.data.session.data });
  };

  const setFormTableEditable = () => {
    setFormEditable(true);
    setButtonDisable(false);
  };

  if (formEditable === false && buttonDisable === false) {
    setButtonDisable(true);
  }

  const exitForm = () => {
    setTotal(0);
    setFormEditable(false);
    setButtonDisable(true);
  };
  const getList = (parameter) => {
    const count = parameter.max / parameter.step;
    const state = [parameter.min];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < count; index++) {
      const y = state[state.length - 1] + parameter.step || parameter.step;
      if (y > parameter.max) console.log('Max');
      else state.push(y);
    }
    return state;
  };
  if (!channel)
    return (
      <div>
        {userType === 'ROLE_SUPPLIER'
          ? 'Welcome! To set up negobot for your online store, please download and read the set up guide from above to get started.'
          : 'Welcome! To begin your transaction, please search for the products you are interested in.'}
      </div>
    );
  return (
    <>
      <AntdLayout className={classes.root}>
        <Header style={{ zIndex: 1, position: 'fixed', width: '100%' }}>
          <ChannelInfoWrapper
            sid={sid}
            sendMessage={sendMessage}
            friendlyName={friendlyName}
            role={role || ''}
            customer={channel?.customer}
            products={channel?.products}
            orderNo={channel?.rfq_number}
          />
        </Header>
        <div style={{ marginTop: '60px' }}>
          <ErrorResult title="Error!" />
          {channel && (
            <>
              <NegotiationMessage
                by="bot"
                render={() => (
                  <p>
                    Thanks for expressing your interest in buying
                    {` ${channel.session?.rfq_items?.map((r) => r.name).toString()}.`}
                  </p>
                )}
              />
              {channel.session &&
                channel.session.offers &&
                channel.session.offers.map((o) => {
                  // eslint-disable-next-line no-param-reassign

                  return o.by === 'buyer' ? (
                    <NegotiationMessage
                      key={`buyer_${o.round}`}
                      by="buyer"
                      render={() => (
                        <>
                          <span className={classes.date}>{moment(o.at).format('lll')}</span>
                          <h4 style={{ float: 'right', fontWeight: 700 }}>
                            {`Total: ${formatter.format(o.total || 0)}`}
                          </h4>
                          {role === 'ROLE_BUYER' ? (
                            <StandardNegotiateTable
                              rowkey={`${o.by}${o.round}`}
                              columns={headersEdit(true)}
                              data={o.rfq_offer.rfq_items}
                              editable
                            />
                          ) : (
                            <StandardNegotiateTable
                              rowkey={`${o.by}${o.round}`}
                              columns={headersEdit(true)}
                              data={o.rfq_offer.rfq_items}
                            />
                          )}
                          <ProcurementParameters
                            session={false}
                            value={o.rfq_offer.rfq_parameters}
                            schema={schema}
                            param={parameters.procurement_parameters}
                          />
                        </>
                      )}
                    />
                  ) : (
                    <>
                      <div className={classes.date}>{moment(o.at).format('lll')}</div>
                      <NegotiationMessage
                        key={`bot_${o.round}`}
                        by={`${o.by}`}
                        isOffer
                        render={() => (
                          <>
                            <Col>
                              {o.round === 0 && <p>We would like you to consider this offer:</p>}
                              {o.round !== 0 && (
                                <p>
                                  {o.message
                                    ? o.message
                                    : 'We would like you to consider this offer'}
                                </p>
                              )}
                              <h4 style={{ float: 'right', fontWeight: 700 }}>
                                Total:
                                {` ${formatter.format(o.total || 0)}`}
                              </h4>
                            </Col>

                            <StandardNegotiateTable
                              rowkey={`${o.by}${o.round}`}
                              columns={headersEdit(false)}
                              data={o.rfq_offer.rfq_items}
                            />
                            <br />
                            <ProcurementParameters
                              session={false}
                              value={o.rfq_offer.rfq_parameters}
                              schema={schema}
                              param={parameters.procurement_parameters}
                            />
                            <br />
                            {isLastOfferByBot &&
                              role === 'ROLE_BUYER' &&
                              buttonDisable &&
                              isButtonDisabled &&
                              lastOffer.round === o.round && (
                                <Row>
                                  <Col>
                                    <Button
                                      title="Accept Offer"
                                      type="default"
                                      className={classes.confirmButton}
                                      onClick={confirmNegotiation}
                                    >
                                      Accept
                                    </Button>
                                    {!isCompleted && (
                                      <Button
                                        title="Make Counter Offer"
                                        type="default"
                                        className={classes.negotiateButton}
                                        onClick={setFormTableEditable}
                                      >
                                        Make Offer
                                      </Button>
                                    )}

                                    <Button
                                      title="Exit Negotiation"
                                      type="default"
                                      className={classes.exitButton}
                                      onClick={exitNegotiation}
                                    >
                                      Exit
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                          </>
                        )}
                      />
                    </>
                  );
                })}

              {isLastOfferByBot && role === 'ROLE_BUYER' && formEditable && (
                <NegotiationMessage
                  by="buyer"
                  render={() => (
                    <>
                      <Card
                        bodyStyle={{ padding: '7px 0' }}
                        style={{
                          borderRadius: '5px',
                          boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)',
                        }}
                      >
                        <Row style={{ margin: '10px' }}>
                          <Col span={10} style={{ font: 'Roboto' }}>
                            Order No:
                            <strong>{` ${channel?.rfq_number}`}</strong>
                          </Col>
                          <Col span={7}>
                            Total:
                            <strong>{` ${formatter.format(total.toFixed(2))}`}</strong>
                          </Col>
                          {lastItem && lastItem ? (
                            <Col span={7} style={{ backgroundColor: '#e0e0e063' }}>
                              Last Bid Total:
                              <strong>
                                {` ${formatter.format((lastItem && lastItem.total) || 0)}`}
                              </strong>
                            </Col>
                          ) : (
                            ''
                          )}
                        </Row>
                        <Row>
                          <Col>
                            <EditableFormNegotiate
                              rowKey="id"
                              pagination={false}
                              onSave={onItemsSave}
                              form={form}
                              className={classes.form}
                              headers={
                                lastItem && lastItem ? getHeaders(false) : headersEdit(false)
                              }
                              data={rfqItems}
                            />
                          </Col>
                        </Row>
                      </Card>
                      <br />
                      {lastItem &&
                      lastItem &&
                      parameters &&
                      parameters.procurement_parameters.length > 0 ? (
                        <>
                          <Row>
                            <Col span={5} />
                            <Col span={12}>
                              <Typography> Current Bid</Typography>
                            </Col>
                            <Col span={7}>
                              <Typography> Last Bid</Typography>
                            </Col>
                          </Row>
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      <Row>
                        <Col span={5} />
                        <Col span={12}>
                          {parameters &&
                            parameters?.procurement_parameters?.map((p) => {
                              const parameter = getParamLabelAndUnit(
                                p.name,
                                schema,
                                parameters.procurement_parameters,
                              );
                              return (
                                <Col key={`form_${p.name}`}>
                                  {channel?.session ? (
                                    <Form labelCol={{ span: 13 }} wrapperCol={{ span: 10 }}>
                                      <FormItem
                                        labelAlign="left"
                                        style={{
                                          marginBottom: '5px',
                                          fontSize: '15px',
                                          fontWeight: 400,
                                        }}
                                        label={
                                          parameter.unit
                                            ? `${parameter.label}`
                                            : `${parameter.label}`
                                        }
                                      >
                                        {form.getFieldDecorator(`${p.name}`, {
                                          initialValue: '',
                                          rules: [
                                            {
                                              required: parameter.type !== 'checkbox',
                                              message: `${parameter.label} is required`,
                                            },
                                            {
                                              message: `Permissible values are ${getList(
                                                parameter,
                                              ).join(',')}`,
                                              validator: (_, value, cb) => {
                                                if (!value || parameter.type === 'checkbox')
                                                  return cb();
                                                return getList(parameter).indexOf(value) === -1
                                                  ? cb(true)
                                                  : cb();
                                              },
                                            },
                                          ],
                                          valuePropName:
                                            parameter.type === 'checkbox' ? 'checked' : 'value',
                                          // initialValue: parameters.procurement_parameters.find(p => p.name === param.name) && parameters.procurement_parameters.find(p => p.name === param.name)[item],
                                        })(
                                          parameter.type === 'checkbox' ? (
                                            <Checkbox />
                                          ) : (
                                            <InputNumber
                                              placeholder={`In ${parameter.unit}`}
                                              min={parameter.min}
                                              max={parameter.max}
                                              step={parameter.step}
                                              style={{ width: '100%' }}
                                            />
                                          ),
                                        )}
                                      </FormItem>
                                    </Form>
                                  ) : (
                                    <Row type="flex" align="middle" justify="center">
                                      <Spin />
                                    </Row>
                                  )}
                                </Col>
                              );
                            })}
                        </Col>
                        <Col span={7}>
                          <ProcurementParameters
                            session
                            value={lastItem && lastItem.rfq_offer.rfq_parameters}
                            schema={schema}
                            param={channel.session?.bot_parameter?.procurement_parameters}
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Button
                            title="Submit Your Offer"
                            type="default"
                            className={classes.negotiateButton}
                            // eslint-disable-next-line no-alert
                            onClick={makeOffer}
                          >
                            Make Offer
                          </Button>
                          <Button
                            title="Exit From Negotiation Input Box"
                            type="default"
                            className={classes.exitButton}
                            onClick={exitForm}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                />
              )}

              {isCompleted &&
              role === 'ROLE_BUYER' &&
              !isAllRoundsCompleted &&
              !isCustomerCancelled &&
              !isCustomerAccepted ? (
                <>
                  <div className={classes.date}>{moment(lastOffer.at).format('lll')}</div>
                  <NegotiationMessage
                    by="bot"
                    render={() => (
                      <>
                        <p>Thank you and please confirm your offer</p>
                        <StandardNegotiateTable
                          rowkey={`${'Bot'}${10}`}
                          columns={headersEdit(false)}
                          data={lastOffer.rfq_offer.rfq_items}
                        />
                        <br />
                        {isButtonDisabled && (
                          <>
                            <Col>
                              <Button
                                type="default"
                                className={classes.confirmButton}
                                onClick={confirmNegotiation}
                              >
                                Confirm
                              </Button>
                              <Button
                                type="default"
                                className={classes.exitButton}
                                onClick={exitNegotiation}
                              >
                                Exit
                              </Button>
                            </Col>
                          </>
                        )}
                      </>
                    )}
                  />
                </>
              ) : (
                ''
              )}
              {isCustomerCancelled ? (
                <NegotiationMessage
                  by="bot"
                  render={() => (
                    <>
                      <p>
                        Sorry we couldn’t arrive at a deal today. Hope to do business with you
                        sometime in the future.
                      </p>
                    </>
                  )}
                />
              ) : (
                ''
              )}

              {isCompleted && isCustomerAccepted && role === 'ROLE_BUYER' ? (
                <NegotiationMessage
                  by="bot"
                  render={() => (
                    <>
                      <p>
                        That&apos;s Great! I will get my supervisor to review and confirm the deal
                        very soon. Thank you!
                      </p>
                    </>
                  )}
                />
              ) : (
                ''
              )}

              {isSupplierCancelled ? (
                <>
                  <div className={classes.date}>{moment(channel?.updated_date).format('lll')}</div>
                  <NegotiationMessage
                    by="bot"
                    render={() => (
                      <>
                        <p>
                          I am afraid we can no longer do this deal. I am very sorry for the
                          inconvenience caused.
                        </p>
                      </>
                    )}
                  />
                </>
              ) : (
                ''
              )}
              {isNegotiationCompleted ? (
                <>
                  <div className={classes.date}>{moment(channel?.updated_date).format('lll')}</div>
                  <NegotiationMessage
                    by="bot"
                    render={() => (
                      <>
                        <p>
                          Thank you for your business! We confirm your offer with the following
                          details:
                        </p>
                        <StandardNegotiateTable
                          rowkey={`${'Bot'}${10}`}
                          columns={headersEdit(false)}
                          data={lastOffer.rfq_offer.rfq_items}
                        />
                        <br />
                        <p>Please release the Purchase Order (PO).</p>
                        <p>
                          To release the PO click on &quot;My Transactions&quot; in the top right
                          drop down menu on the EzySource main page and you will be directed to
                          &quot;Purchase Orders&quot; tab where you can release the PO.
                        </p>
                      </>
                    )}
                  />
                </>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </AntdLayout>
    </>
  );
};

export default Form.create()(Layout);
