import { Col, Row } from 'antd';

import React from 'react';
import botIcon from '../assets/bot-icon-new.svg';
import coPilotIcon from '../assets/co-pilot-icon.svg';
import useStyles from './styles';
import userIcon from '../assets/buyer-icon.svg';

const NegotiationMessage = ({ render, by }) => {
  const classes = useStyles();
  return (
    <Row className={classes.chatBox} type="flex">
      {by === 'bot' ? (
        <Col>
          <img className={classes.botIcon} alt="bot-icon" src={botIcon} />
        </Col>
      ) : null}
      {by === 'supplier' ? (
        <Col>
          <img className={classes.coPilotIcon} alt="co-pilot-icon" src={coPilotIcon} />
        </Col>
      ) : null}
      <Col
        span={by === 'bot' || by === 'supplier' ? 14 : 19}
        offset={by === 'bot' || by === 'supplier' ? 0 : 3}
        className={by === 'bot' || by === 'supplier' ? classes.botReply : classes.userReply}
      >
        {render()}
      </Col>
      {by === 'buyer' ? (
        <Col>
          <img className={classes.userIcon} alt="user-icon" src={userIcon} />
        </Col>
      ) : null}
    </Row>
  );
};

export default NegotiationMessage;
